<template>
  <div>
    <GmapMap
      :zoom="10"
      id="map"
      :center="center"
      ref="map"
      style="height: 800px; width: 800px"
      :options="{
        streetViewControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      }"
    >
      <GmapMarker :position="center" label="'test'" @click="marker_click()">
      </GmapMarker>
    </GmapMap>
    <div v-if="showDialog" class="dialog-overlay">
      <div class="dialog-container">
        <div class="dialog-content">
          <h2>Informations du marqueur</h2>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Nom"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-btn color="success" class="mr-4" @click="submit">
              Envoyer
            </v-btn>
            <v-btn color="error" class="mr-4" @click="closeDialog">
              Annuler
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      center: { lat: 34.4, lng: 2.78 },
      showDialog: false,
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Le nom est requis",
        (v) =>
          (v && v.length <= 10) || "Le nom doit faire 10 caractères maximum",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "L'e-mail est requis",
        (v) => /.+@.+\..+/.test(v) || "L'e-mail doit être valide",
      ],
      map: null,
      marker: null,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {},
    marker_click() {
      this.showDialog = true;
    },
    submit() {
      if (this.$refs.form.validate()) {
        // Logique d'envoi du formulaire
        console.log("Nom:", this.name);
        console.log("E-mail:", this.email);
        this.closeDialog();
      }
    },
    closeDialog() {
      this.showDialog = false;
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  width: 90%;
}

.dialog-content {
  padding: 20px;
}
</style>
